import { Stack, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header";
const GreviancePolicy = () => {
  return (
    <>
      <Helmet>
        <title>Greviance Policy | vKover</title>
      </Helmet>
      <Container maxWidth='lg'>
        <Header isTransparent />
        <Stack mb={4}>
          <Typography variant='h1' fontSize={"28px"} mt={2} fontWeight='400'>
            Complaints & Grievance
          </Typography>

          <Typography variant='body1' fontSize={"20px"} mb={2} mt={2}>
            For any complaints, services related issues or policy, claim related
            queries/ customer can reach us on our number{" "}
            <a href='tel:+919288009111'>+91 92880 09111</a> or mail to{" "}
            <a href='mailto:info@vkover.com'>info@vkover.com</a>. For
            escalations customer can write to us at{" "}
            <a href='mailto:po@vkover.com'>po@vkover.com</a> or call us at{" "}
            <a href='tel:+919846100061'>+91 98461 00061</a>. For more details on
            complaints and grievances, customers are requested to visit{" "}
            <a href=' http://www.policyholder.gov.in/'>
              http://www.policyholder.gov.in
            </a>
            .
          </Typography>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default GreviancePolicy;
